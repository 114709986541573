import '../css/front_page.scss';

import * as bootstrap from 'bootstrap';

// cart modal with jquery
// $(document).ready(function () {
//     if ($('#cart-modal-status').data('show-cart-modal') === true) {
//         $('#cart-modal').modal();
//     }
// });

// $(document).ready(function () {
//     if ($('#danish-link-modal-status').data('show-danish-link-modal')) {
//         $('#danish-link-modal').modal();
//     }
// });

// function getZipcodeFieldElement() {
//     return $('#zipcode_form_zipcode');
// }

// function getPopoverBoundary() {
//     return $('main')[0];
// }

// cart modal with vanilla js
const cartModalContent = document.querySelector('#cart-modal');
if (cartModalContent) {
    const cartModal = new bootstrap.Modal(cartModalContent, {});

    cartModal.show();
}

// danish link modal with vanilla js
const danishLinkModalContent = document.querySelector('#danish-link-modal');
if (danishLinkModalContent) {
    const danishLinkModal = new bootstrap.Modal(danishLinkModalContent, {});

    danishLinkModal.show();
}

// cart popover
const cartButton = document.querySelector('#cart-popover-button');
if (cartButton) {
    new bootstrap.Popover(cartButton, {
        html: true,
        placement: "bottom",
        container: "body",
        boundary: document.querySelector('main'),
        delay: {
            "hide": 100
        },
        content: function () {
            const content = document.querySelector("#cart-popover-content").cloneNode(true);
            content.removeAttribute("hidden");
            return content;
        },
        template: '<div class="popover popover-cart" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
    });
}

// zipcode popover
const zipcodeForm = document.querySelector('#zipcode_form_zipcode');
if (zipcodeForm) {
    new bootstrap.Popover(zipcodeForm, {
        html: true,
        placement: "bottom",
        container: "body",
        boundary: document.querySelector('main'),
        delay: {
            "hide": 100
        },
        content: function () {
            const content = document.querySelector("#zipcode-popover-content").cloneNode(true);
            content.removeAttribute("hidden");
            return content;
        },
        template: '<div class="popover popover-zipcode bg-primary" role="tooltip"><div class="popover-arrow"></div><div class="popover-body text-white text-shadow"></div></div>',
        trigger: "manual",
    });

    const inArray = function (needle, haystack) {
        let length = haystack.length;
        for (let i = 0; i < length; i++) {
            if (haystack[i] === needle) return true;
        }
        return false;
    }
}


// Intersection Observer API navbar
const navContainer = document.querySelector('.nav-container');
if (navContainer) {
    const handleIntersection = (entries) => {
        entries.map((entry) => {
            if (entry.isIntersecting) {
                navContainer.classList.remove('sticky');
            } else {
                navContainer.classList.add('sticky');
            }
        });
    }

    if (typeof window.IntersectionObserver !== 'undefined') {
        const heroBanner = document.querySelector('.hero-image');

        const options = {threshold: 0.5};
        const observer = new IntersectionObserver(handleIntersection, options);

        if (heroBanner !== null) {
            observer.observe(heroBanner);
        }
    }
}
